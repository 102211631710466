import { ResourceExampleIF } from '@/interfaces'

export const resourceModel: Array<ResourceExampleIF> = [
  {
    id: 1,
    displayName: '',
    message: 'If you are reading this... my ACTIONS didnt work :('
  },
  {
    id: 2,
    displayName: '',
    message: 'You should never see this text!'
  }
]
